.arrow {
	border-right-color: transparent;
	border-bottom-color: transparent;
	height: 14px;
	width: 14px;
	border-style: solid;
	border-width: 1px;
	background-color: inherit;
}

.arrowLight {
	border-top-color: var(--theme-grey-light);
	border-left-color: var(--theme-grey-light);
}

.arrowDark {
	border-top-color: var(--theme-black);
	border-left-color: var(--theme-black);
}

.popover {
	padding: 10px 8px;

	/* prettier-ignore */
	box-shadow:
		0 6px 10px 0 rgb(0 0 0 / 14%),
		0 1px 18px 0 rgb(0 0 0 / 12%),
		0 3px 5px -1px rgb(0 0 0 / 20%);
}

.bottomContainer {
	top: 0.5em;
}

.popoverBottom {
	transform-origin: top center;
	top: 6px;
}

.arrowBottom {
	transform: rotate(45deg);
}

.topContainer {
	bottom: 1.25rem;
}

.popoverTop {
	position: absolute;
	bottom: 6px;
}

.arrowTop {
	bottom: 0;
	transform: rotate(225deg);
}
