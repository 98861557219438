.sidebar {
	visibility: hidden;
	animation-duration: 0.25s;
	animation-fill-mode: both;
}

.sidebarVertical {
	width: 100vw;
}

.sidebarVerticalThreeQuarters {
	max-width: 100vw;
}

@media (--breakpoint-not-small) {
	.sidebarVertical {
		min-width: 25rem;
		max-width: 25rem;
	}

	.sidebarVerticalThreeQuarters {
		min-width: 25rem;
		max-width: 75vw;
	}
}

.sidebarHorizontal {
	min-width: 100vw;
}

@media (--breakpoint-not-small) {
	.sidebarHorizontal {
		height: 118px;
	}
}
