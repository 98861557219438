:root {
	--checkbox-fade: 0.2s;
	--checkbox-border: currentColor;
	--checkbox-fill: #3a99fc;
	--checkbox-fill-disabled: #a6ccfe; /* Equivalent to 50% opacity on white background */
}

.checkboxLabel {
	box-sizing: border-box;
	list-style: none;
	line-height: unset;
}

.outer {
	box-sizing: border-box;
	list-style: none;
	outline: none;
	margin-top: 0.1em;
}

.outer:focus-within {
	outline: 0.15em solid #000;
}

.inner {
	width: 0.7em;
	height: 0.7em;
	border: 0.0625em solid var(--checkbox-border);
	transition: all var(--checkbox-fade);

	& ~ .checkmark {
		transform: rotate(45deg) scale(0);
		position: absolute;
		left: 0.25em;
		top: 0.025em;
		width: 0.2031em;
		height: 0.5em;
		border: 0.125em solid #fff;
		border-top: 0;
		border-left: 0;
		content: ' ';
		transition: transform var(--checkbox-fade) cubic-bezier(0.71, -0.46, 0.88, 0.6);
	}
}

.input:checked ~ .inner {
	background-color: var(--checkbox-fill);
	border-color: var(--checkbox-fill);
}

.input:checked ~ .checkmark {
	transform: rotate(45deg) scale(1);
	position: absolute;
	border: 0.125em solid #fff;
	border-top: 0;
	border-left: 0;
	content: ' ';
	transition: transform var(--checkbox-fade) cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
}

.input:disabled ~ .inner {
	background-color: var(--theme-grey-lighter);
	border-color: var(--theme-grey-light);
}

.input:checked:disabled ~ .inner {
	background-color: var(--checkbox-fill-disabled);
	border-color: var(--checkbox-fill-disabled);
}
