.infoBannerLink {
	& a {
		color: var(--theme-info);
	}
}

.warningBannerLink {
	& a {
		color: var(--theme-warning);
	}
}

.errorBannerLink {
	& a {
		color: var(--theme-error);
	}
}

.bannerStyleReset {
	& p {
		margin: 0;
		line-height: normal;
	}

	& a {
		text-decoration: none;
		line-height: normal;
	}

	& a:hover {
		text-decoration: underline;
	}
}
