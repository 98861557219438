/* TODO: EFDC-16856: reduce use of custom css for header */
.navMenuLayout {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	justify-items: center;
	grid-template-areas: 'menu account projects call cart';
}

.divider {
	border-bottom: 0.125rem solid var(--theme-accent);
}

.logoDimensions {
	max-width: 220px;
	height: 1.625rem;
}

.iconTransform {
	transform: scale(0.75);
}
