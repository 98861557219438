/* stylelint-disable unit-whitelist */

.navHoverIndicator::after {
	border: 7px solid transparent;
	border-bottom-color: #fff;
	bottom: -6px;
	content: '';
	margin-right: -7px;
	position: absolute;
	right: 50%;
	z-index: 1001;
	transition: all 0s ease;
}
