.searchContainer {
	will-change: width, max-width;
	transition-property: width, max-width;
	transition-duration: 0.5s;
	max-width: 100%;
}

@media (--breakpoint-not-small) {
	.searchContainer {
		max-width: 50%;

		&.wide {
			width: 85%;
			max-width: 85%;
		}
	}
}

.cancelButton {
	transition-property: max-width, margin, padding, border-width, opacity;
	transition-duration: 0.3s;
	transition-timing-function: ease-out;
	overflow: hidden;
	max-width: 5rem;

	&.hide {
		max-width: 0;
		padding-right: 0;
		padding-left: 0;
		margin-right: 0;
		margin-left: 0;
		border-left-width: 0;
		border-right-width: 0;
		opacity: 0;
	}
}

.focusWithin:focus-within {
	outline: auto;
	outline-color: var(--theme-grey-darker);
}

.button {
	height: 44px;
	display: flex;
	align-items: center;
}
