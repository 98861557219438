.navigationStyles {
	height: 56px;
}

@media (--breakpoint-not-small) {
	.navigationStyles {
		height: auto;
	}
}

.projectsIcon {
	width: 1em;
	height: 1em;
}

.globalTop {
	& p {
		margin: 0;
		line-height: normal;
	}

	& a {
		color: var(--theme-primary);
		text-decoration: none;
		font-weight: normal;
		line-height: 1;
	}

	& a:hover {
		text-decoration: underline;
	}

	& span {
		line-height: normal;
	}

	& em {
		line-height: normal;
	}

	& strong {
		line-height: normal;
	}

	& li {
		line-height: normal;
	}

	& :global(.rt-color-primary) {
		color: var(--theme-primary);
	}

	& :global(.rt-color-secondary) {
		color: var(--theme-secondary);
	}

	& :global(.rt-color-red) {
		color: var(--theme-error);
	}

	& :global(.rt-color-blue) {
		color: var(--theme-primary);
	}

	& :global(.rt-color-green) {
		color: var(--theme-primary);
	}

	& :global(.rt-color-dark-grey) {
		color: var(--theme-grey-darker);
	}

	& :global(.rt-color-light-grey) {
		color: var(--theme-grey-lighter);
	}

	& :global(.rt-size-60) {
		font-size: 8.4px;
		line-height: 12.6px;
	}

	& :global(.rt-size-75) {
		font-size: 10.5px;
		line-height: 13.3px;
	}

	& :global(.rt-size-89) {
		font-size: 12.46px;
	}

	& :global(.rt-size-100) {
		font-size: 14px;
	}

	& :global(.rt-size-120) {
		font-size: 17px;
	}

	& :global(.rt-size-150) {
		font-size: 21px;
	}

	& :global(.rt-size-200) {
		font-size: 28px;
	}

	& :global(.rt-size-300) {
		font-size: 42px;
	}
}
