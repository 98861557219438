.skipLink {
	left: -10000px;
	top: auto;
	overflow: hidden;
	position: absolute;
}

.skipLink:focus,
.skipLink:active {
	display: inline-block;
	left: 5px;
	top: 5px;
	outline: var(--theme-primary) auto 2px !important;
}
