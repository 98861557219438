@media (--breakpoint-large) {
	.omniLogo {
		width: 266px;
	}
}

@media (--breakpoint-medium) {
	.omniLogo {
		width: 181px;
	}
}

@media (--breakpoint-large) {
	.iconsGap {
		gap: 32px;
	}
}

@media (--breakpoint-medium) {
	.iconsGap {
		gap: 16px;
	}
}
