.mobileNavProBadge {
	width: 46px;
	height: 24px;
}

.buildWithFergusonLogo {
	width: 18%;
}

.mobileNavDrawer {
	width: 92%;
}

.menuButton {
	transition-property: max-width, margin, padding, border-width, opacity;
	transition-duration: 0.3s;
	transition-timing-function: ease-out;
	overflow: hidden;
	max-width: 3rem;

	&.hide {
		max-width: 0;
		padding-right: 0;
		padding-left: 0;
		margin-right: 0;
		margin-left: 0;
		border-left-width: 0;
		border-right-width: 0;
		opacity: 0;
	}
}
