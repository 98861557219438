.overlapBorder {
	margin-left: -1px;
}

@media (--breakpoint-large) {
	.segButton:hover {
		background-color: white;
		color: var(--theme-primary);
		border-color: var(--theme-primary);
	}

	.segButton:focus {
		background-color: var(--theme-primary);
		color: white;
		border-color: var(--theme-primary);
	}
}
